import React from 'react';
import {
  TextAreaGlimmerProps,
  TextAreaProps,
} from '../../components/fields/TextArea';
import TextAreaComponent, {
  TextAreaGlimmer as TextAreaGlimmerComponent,
} from '../../components/fields/TextArea';
import editStyles from '../../../../stylesheets/components/edit.module.scss';
import { useThemeContext } from '../../components/ThemeContextProvider';
import classNames from 'classnames';

const TextArea = ({
  field,
  mode,
  onChange,
  colSizeClass = 'pm-flex-col-small',
}: TextAreaProps) => {
  const { theme } = useThemeContext();

  return (
    <div
      className={classNames(
        'pm-flex',
        'pm-flex-col',
        'pm-form_field',
        colSizeClass
      )}
    >
      <TextAreaComponent
        field={field}
        mode={mode}
        variant={theme === 'dark1' ? 'filled' : 'outlined'}
        onChange={onChange}
        editClassNames={editStyles.pmEdit}
        displayClassNames="pm-display"
      />
    </div>
  );
};

export const TextAreaGlimmer = ({ field }: TextAreaGlimmerProps) => {
  return (
    <TextAreaGlimmerComponent
      field={field}
      glimmerClassNames="pm-edit glimmerElement"
    />
  );
};

export default TextArea;
